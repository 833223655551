<template>
  <v-container class="pa-0 app-fill-height mobile-container">
    <v-container tag="section" class="pa-0 mb-10 mobile-container">
      <v-responsive
        max-width="800px"
        class="mx-auto"
      >
          <v-card class="page-box-wrapper elevation-3">
            <div class="page-box-content">
              <h2 class="h2-green">
                Как начать<br />
                зарабатывать с LR
              </h2>
              <div class="img-top-box">
                <div class="text-center">
                  <img src="/img/biz/lrpreza.png" alt="" class="lr-preza" />
                </div>
              </div>
              <h3 class="mt-3 mb-3 text-center">Онлайн-бизнес, который помещается в твоём телефоне</h3>
              <div class="about">
                <p>
                  На этой странице рассказываем:
                </p>
                <ul class="ul-check pl-0">
                  <li>✔ Кто мы такие и чем занимаемся</li>
                  <li>✔ Как работает бизнес LR и почему он сейчас набирает популярность</li>
                  <li>✔ Сколько ты можешь зарабатывать, используя нашу систему</li>
                </ul>
                <div
                  class="wistia_responsive_padding mt-7"
                  style="padding: 56.25% 0 0 0; position: relative"
                >
                  <div
                    class="wistia_responsive_wrapper"
                    style="
                      height: 100%;
                      left: 0;
                      position: absolute;
                      top: 0;
                      width: 100%;
                    "
                  >
                  <iframe src="https://fast.wistia.net/embed/iframe/fri80k3kva?web_component=true&seo=true" title="Жить лучше, вместе с LR Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" width="100%" height="100%"></iframe>
                  </div>
                </div>
                <p class="mt-7">Преимущества бизнеса с LR</p>
                <ul class="ul-check">
                  <li>
                    <v-icon icon="mdi-check">mdi-check</v-icon>
                    <strong>Доход неограничен</strong> — зависит только от твоих амбиций и способностей
                  </li>
                  <li>
                    <v-icon icon="mdi-check">mdi-check</v-icon>
                    <strong>Работай из дома</strong> — бизнес всегда под рукой, нужен только Интернет, можно совмещать с основной работой или семьёй
                  </li>
                  <li>
                    <v-icon icon="mdi-check">mdi-check</v-icon>
                    <strong>Отсутствие рисков</strong> — нет крупных вложений, аренды или сотрудников.
                  </li>
                  <li>
                    <v-icon icon="mdi-check">mdi-check</v-icon>
                    <strong>Качественные продукты</strong> — легко продвигать то, что приносит реальную пользу
                  </li>
                  <li>
                    <v-icon icon="mdi-check">mdi-check</v-icon>
                    <strong>Готовая система обучения</strong> — есть чёткий алгоритм действий и наставники
                  </li>
                  <li>
                    <v-icon icon="mdi-check">mdi-check</v-icon>
                    <strong>Онлайн-инструменты</strong> — современные технологии, которые делают бизнес простым и понятным
                  </li>
                </ul>
                <div class="wistia_responsive_padding mt-7" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/w92200v7ez?web_component=true&seo=true" title="Бизнес 1. Знакомство с LR Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" width="100%" height="100%"></iframe></div></div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">Тебе нужен этот бизнес, если:</h2>
                  <div class="text-center">
                    <img
                      src="/img/biz/prorelife-instruments.png"
                      alt=""
                      class="lr-preza"
                    />
                  </div>
                  <ul class="ul-check">
                    <li>
                      <v-icon icon="mdi-check">mdi-check</v-icon>
                        Хочешь увеличить свои доходы в несколько раз
                    </li>
                    <li>
                      <v-icon icon="mdi-check">mdi-check</v-icon>
                      Надоело жить от зарплаты до зарплаты
                    </li>
                    <li>
                      <v-icon icon="mdi-check">mdi-check</v-icon>
                      Хочешь уволиться с работы по найму и работать на себя, но не хочешь рисковать
                    </li>
                    <li>
                      <v-icon icon="mdi-check">mdi-check</v-icon>
                      Не хватает времени на себя, семью и близких
                    </li>
                    <li>
                      <v-icon icon="mdi-check">mdi-check</v-icon>
                      Хочешь чаще радовать себя и близких дорогими покупками и качественным отдыхом
                    </li>
                    <li>
                      <v-icon icon="mdi-check">mdi-check</v-icon>
                      Чувствуешь усталость от рутины и однообразия
                    </li>
                  </ul>
                  <div class="wistia_responsive_padding mt-7" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/xqhg4l3gom?web_component=true&seo=true" title="Бизнес Система построения бизнеса Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" width="100%" height="100%"></iframe></div></div>
                  
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">Преимущества бизнеса в партнерстве с LR</h2>
                  <p class="mt-5">
                    ✅ Лёгкий старт с минимальными рисками и вложениями
                  </p>
                  <p class="mt-3">
                    Компания берёт на себя всю сложную работу: производство, сертификацию, упаковку, доставку продукции, анализ рынка и создание востребованных продуктов.
                  </p>
                  <p class="mt-3">
                    Тебе не нужно:
                  </p>
                  <ul class="ul-check">
                    <li>
                      <v-icon icon="mdi-check">mdi-check</v-icon>
                      Открывать ИП на старте
                    </li>
                    <li>
                      <v-icon icon="mdi-check">mdi-check</v-icon>
                      Закупать большие партии товаров
                    </li>
                    <li>
                      <v-icon icon="mdi-check">mdi-check</v-icon>
                      Арендовать офис или склад
                    </li>
                    <li>
                      <v-icon icon="mdi-check">mdi-check</v-icon>
                      Нанимать сотрудников
                    </li>
                    <li>
                      <v-icon icon="mdi-check">mdi-check</v-icon>
                      Вести бухгалтерию
                    </li>
                  </ul>
                  <p class="mt-3">
                    Для старта достаточно заказать для себя стартовый набор, чтобы убедиться в качестве продукции и получить личный результат.
                  </p>
                  <h3 class="h3-green mt-5">
                    🤝 Ты не один — за тобой всегда стоит команда!
                  </h3>
                  <p class="mt-3">Ты получаешь:</p>
                  <ul class="ul-check">
                    <li><v-icon icon="mdi-check">mdi-check</v-icon>Наставничество и постоянную поддержку</li>
                    <li><v-icon icon="mdi-check">mdi-check</v-icon>Оперативные ответы на любые вопросы</li>
                    <li><v-icon icon="mdi-check">mdi-check</v-icon>Командные чаты, встречи, регулярные обучающие мероприятия</li>
                    <li><v-icon icon="mdi-check">mdi-check</v-icon>Техническую поддержку от компании</li>
                  </ul>
                  <p class="mt-3">Ты всегда будешь в окружении единомышленников, которые помогут тебе двигаться к цели</p>
                  <h3 class="h3-green mt-5">🕑 Гибкость и комфорт — совмещай с работой или другим бизнесом</h3>
                  <p class="mt-3">Ты можешь успешно начать, уделяя бизнесу всего 2–3 часа в день. Работай удалённо из любой удобной точки мира, в то время, когда тебе удобно.</p>
                  <h3 class="h3-green mt-5">💸 Доход зависит только от твоих амбиций</h3>
                  <ul class="ul-check">
                    <li><v-icon icon="mdi-check">mdi-check</v-icon>Возможность создать пассивный доход благодаря развитию клиентской и партнёрской сети.</li>
                  </ul>
                  <h3 class="h3-green mt-5">🌍 Полная свобода действий</h3>
                  <ul class="ul-check">
                    <li><v-icon icon="mdi-check">mdi-check</v-icon>Работай онлайн из любой страны и города</li>
                    <li><v-icon icon="mdi-check">mdi-check</v-icon>Никакой привязки к офису или складу</li>
                    <li><v-icon icon="mdi-check">mdi-check</v-icon>Больше свободного времени на семью, хобби, путешествия</li>
                    <li><v-icon icon="mdi-check">mdi-check</v-icon>Даже в отпуске твой бизнес продолжает работать благодаря команде</li>
                  </ul>
                  <h3 class="h3-green mt-5">🏆 Твой бизнес — твой актив</h3>
                  <p class="mt-3">Ты создаёшь собственный актив в виде:</p>
                  <ul class="ul-check">
                    <li><v-icon icon="mdi-check">mdi-check</v-icon>Клиентской сети</li>
                    <li><v-icon icon="mdi-check">mdi-check</v-icon>Партнёрской сети</li>
                  </ul>
                  <p class="mt-3">Твой доход зависит не только от твоих действий, но и от работы всей команды. Возможность быстрого и простого масштабирования на международном уровне.</p>
                  <h3 class="h3-green mt-5">🎓 Возможность начать без опыта, обучение с нуля</h3>
                  <p class="mt-3">Тебе не нужен особый опыт — научиться можно всему благодаря проверенной системе обучения. К нам приходят люди из абсолютно разных профессий:</p>
                  <ul class="ul-check">
                    <li><v-icon icon="mdi-check">mdi-check</v-icon>Мамы в декрете</li>
                    <li><v-icon icon="mdi-check">mdi-check</v-icon>Предприниматели</li>
                    <li><v-icon icon="mdi-check">mdi-check</v-icon>Сотрудники банков и финансовой сферы</li>
                    <li><v-icon icon="mdi-check">mdi-check</v-icon>IT-специалисты</li>
                    <li><v-icon icon="mdi-check">mdi-check</v-icon>Представители бьюти-индустрии</li>
                    <li><v-icon icon="mdi-check">mdi-check</v-icon>Таксисты, продавцы и пенсионеры</li>
                  </ul>
                  <h3 class="h3-green mt-5">📑 Легальность и прозрачность</h3>
                  <p class="mt-3">Это полностью легальный бизнес:</p>
                  <ul class="ul-check">
                    <li><v-icon icon="mdi-check">mdi-check</v-icon>Белый доход, оплата налогов, пенсионные отчисления</li>
                    <li><v-icon icon="mdi-check">mdi-check</v-icon>Возможность работать как самозанятый</li>
                  </ul>
                  <h3 class="h3-green mt-5">🎁 Передай бизнес по наследству</h3>
                  <p class="mt-3">Ты создаёшь долгосрочный и надёжный источник дохода, который можно оставить детям и даже внукам.</p>
                  <h3 class="mt-5">💡 Начни свой путь к финансовой независимости и свободе с LR уже сегодня!</h3>
                  <div class="wistia_responsive_padding mt-7" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/43m3qc5v9j?web_component=true&seo=true" title="Как работает бизнес LR Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" width="100%" height="100%"></iframe></div></div>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">Как начать?</h2>
                  <div class="text-center mt-10" id="contacts">
                    <h3 class="h3-green">
                      Напишите мне и я расскажу подробнее
                    </h3>
                    <div class="mt-12 mb-7">
                  <div class="d-flex grow flex-wrap">
                        <div
                            class="contact-avatar v-avatar mx-auto v-card--material__avatar elevation-6 grey"
                            style="height: 128px; min-width: 128px; width: 128px"
                        >
                            <div class="v-image v-responsive theme--light">
                                <img v-if="userData.avatar"
                                     :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                     alt=""
                                >
                                <div class="v-responsive__content" style="width: 250px"></div>
                            </div>
                        </div>

                        <v-card-text class="text-center">
                            <h4 class="display-2 font-weight-light mb-3 black--text">
                                {{ userData.name }} {{ userData.last_name }}
                            </h4>
                        </v-card-text>
                    </div>
                    <v-row>
                        <v-col class="text-center">
                            <div class="input-group mb-3">
                                <v-tooltip
                                    top
                                    v-if="userData.phone_whatsapp"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a
                                            style="text-decoration: none"
                                            :href="`https://wa.me/${userData.phone_whatsapp}`"
                                            target="_blank"
                                        >
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                class="mr-4"
                                                color="success"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-whatsapp</v-icon>
                                            </v-btn>
                                        </a>
                                    </template>
                                    <span>Связаться в WhatsApp</span>
                                </v-tooltip>

                                <v-tooltip
                                    top
                                    v-if="userData.telegram"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a
                                            style="text-decoration: none"
                                            :href="`${userData.telegram}`"
                                            target="_blank"
                                        >
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                class="mr-4"
                                                color="blue"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>fab fa-telegram-plane</v-icon>
                                            </v-btn>
                                        </a>
                                    </template>
                                    <span>Связаться в Telegram</span>
                                </v-tooltip>
                            </div>
                        </v-col>
                    </v-row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
      </v-responsive>
    </v-container>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "BizPrezaNew",
    data: () => ({
        cookieShow: true,
    }),
    metaInfo: {
        title: 'Презентация бизнеса в сотрудничестве с LR',
        meta: [
            { vmid: 'description', property: 'description', content: 'Как начать зарабатывать с LR - Презентация бизнеса в сотрудничестве с LR' },
            { vmid: 'og:title', property: 'og:title', content: 'Презентация бизнеса в сотрудничестве с LR' },
            { vmid: 'og:description', property: 'og:description', content: 'Как начать зарабатывать с LR - Презентация бизнеса в сотрудничестве с LR' },
            { vmid: 'og:image', property: 'og:image', content: '' },
        ],
    },
    created() {
        if (!this.$route.query.p) {
            if (this.$cookies.isKey('lifeplus_partner')) {
                const partnerNum = this.$cookies.get('lifeplus_partner')
                this.$router.push ({name: 'BizPrezaNew', query: { p: partnerNum }})
            }
        } else {
            this.$cookies.set('lifeplus_partner', this.$route.query.p, '6m')
        }

        if (this.$cookies.isKey('lifeplus_cookie')) {
            this.cookieShow = false
        }
    },
    computed: {
        ...mapGetters({
            userData: 'user/getPartnerAccess'
        }),
    },
    methods: {
        ...mapActions({
            addNotification: 'application/addNotification',
            showPartnerAccess: 'user/showPartnerAccess',
        }),
        cookieAccept() {
            this.cookieShow = false
            this.$cookies.set('lifeplus_cookie', 'accept', '12m')
        },
    },
    mounted () {
        this.showPartnerAccess(this.$route.query.p)
    },
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: raleway-b;
  src: url("/fonts/raleway-black.woff2");
  font-weight: 300;
}
.app-fill-height {
  font-family: Roboto,sans-serif;
  font-size: 16px !important;
}
ul li {
  margin-bottom: 5px;
}
.contact-avatar {
  top:-30px;
  bottom:0;
}
.page-box-wrapper {
  background: linear-gradient(to right, #99cb9c, #a0c9c8);
  padding: 10px;
}
.page-box-content {
  background-color: #fff;
  padding: 10px 5px 20px;
  border-radius: 3px;
}
.h2-green {
  font-family: raleway-b;
  font-size: clamp(32px, 5vw, 48px);
  text-transform: uppercase;
  text-align: center;
  color: #94b894;
  margin-top: 10px;
}
.box-border-wrapper {
  background: linear-gradient(to right, #99cb9c, #a0c9c8);
  padding: 2px;
  border-radius: 5px;
}
.box-border-content {
  background-color: #fff;
  border-radius: 3px;
  padding: 10px;
}
.h2-title-bg {
  text-align: center;
  font-family: raleway-b;
  color: #fff;
  text-transform: uppercase;
  background: linear-gradient(to right, #99cb9c, #a0c9c8);
  border-radius: 4px;
}
.lr-preza {
  max-width: 350px;
  margin-top: 40px;
}
.about {
  font-size: clamp(16px, 3vw, 18px);
  padding: clamp(10px, 3vw, 40px);
}
.h3-green {
  text-align: center;
  font-family: raleway-b;
  // color: #94b894;
  color: #333;
  text-transform: uppercase;
}
.h4-green {
  color: #94b894;
}
.ul-check {
  list-style-type: none;
  margin-top: 10px;
}
.ul-check li {
  position: relative;
  margin-bottom: 15px;
  padding-left: 30px;
}
.ul-check li .v-icon {
  color: #94b894;
  position: absolute;
  left: 0;
}
@media (min-width: 800px) {
}
@media (max-width: 680px) {
}
@media (max-width: 600px) {
}
@media (max-width: 480px) {
}
@media (max-width: 430px) {
}
@media (max-width: 360px) {
}
</style>